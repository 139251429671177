<template>
  <div v-if="showUpload">
    <Modal v-model="showUpload" width="800" :closable="false">
      <p slot="header" class="text-center">节目单履约</p>
      <div>
        <div class="m-b-10">说明：履约前，请先上传证明材料</div>
        <Upload ref="upload" :headers="headers" :before-upload="handleBeforeUpload" :action="uploadUrl"
          :data="{ programUniqueCode: programUniqueCode }"
          :format="['jpg', 'jpeg', 'png', 'mp4', 'wmv', 'mov', 'avi', 'mpeg']" multiple type="drag">
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
            <p>点击或拖拽文件到这里进行上传，只支持图片/视频类型</p>
          </div>
        </Upload>
      </div>
      <div slot="footer">
        <Button type="text" @click="showUpload = false">取消</Button>
        <Button type="primary" :disabled="uploadFiles.length === 0" @click="onConfirm()">确定履约</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { finishTaskitems } from '@/api/msp/program'
export default {
  props: {
    programUniqueCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showUpload: false,
      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-msp/v2/program/uploadPlayAttaches`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      uploadFiles: []
    }
  },
  methods: {
    init () {
      this.showUpload = true
      this.uploadFiles = []
    },
    handleBeforeUpload (file) {
      this.uploadFiles.push(file)
    },
    onConfirm () {
      finishTaskitems({ programUniqueCode: this.programUniqueCode }).then(res => {
        if (res && res.errcode === 0) {
          this.showUpload = false
          this.$Notice.success({ desc: '操作成功' })
          this.$emit('refreshData')
        }
      })
    }
  }
}
</script>
