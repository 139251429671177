import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 分页获取节目单列表
 * @param {Object} data 参数
 * @returns
 */
export function getElecProgramPage (data) {
  return request.post('/ooh-msp/v2/program/getElecProgramPage', qs.stringify(data))
}

/**
 * 分页获取节目单任务明细
 * @param {Object} data 参数
 * @returns
 */
export function getTaskDetailPage (data) {
  return request.post('/ooh-msp/v2/program/getTaskDetailPage', qs.stringify(data))
}

/**
 * 一键完成节目单对应待完成履约任务
 * @param {Object} data 参数
 * @returns
 */
export function finishTaskitems (data) {
  return request.post('/ooh-msp/v2/program/finishTaskitems', qs.stringify(data))
}
