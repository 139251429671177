<template>
  <div>
    <Row :gutter="8">
      <i-col span="3">
        <DatePicker placeholder="选择节目单日期范围" size="small" v-model="dateRage" type="daterange" split-panels
          style="width:100%" :editable="false" :clearable="false" @on-change="onChangeDate"></DatePicker>
      </i-col>
      <i-col span="4">
        <Button type="primary" icon="ios-search" size="small" @click="onSearch">搜索</Button>
      </i-col>
    </Row>
    <div>
      <Table stripe max-height="720" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
      <div class="paging_style">
        <Page :total="total" size="small" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
          @on-change="handleChangePage"></Page>
      </div>
    </div>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">查看素材</p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
        </i-col>
      </Row>
      <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>
    <done ref="done" :programUniqueCode="selectedUniqueCode" @refreshData="getTableData" />
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { ParseDate } from '@/utils/dateFormat'
import { getElecProgramPage } from '@/api/msp/program'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import Done from './Done'
export default {
  mixins: [sysMixins],
  components: { ImgLightBox, Done },
  data () {
    return {
      visiblePreview: false,
      sortIndex: 0,
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: ''
      },
      dateRage: [],
      list: [],
      total: 0,
      tableLoading: false,
      selectedUniqueCode: '',
      tableColumns: [
        { title: '播放日期', align: 'center', key: 'playDate' },
        {
          title: '素材集合',
          align: 'center',
          render: (h, { row }) => {
            const fileNameArray = row.programFileList.map(x => x.fileName)
            const title = fileNameArray.toString()

            return h('div', title)
          }
        },
        {
          title: '素材预览',
          align: 'center',
          render: (h, { row }) => {
            return h('a', {
              on: {
                click: () => {
                  const postData = {
                    startIndex: 0,
                    imgList: row.programFileList.length === 0 ? [] : row.programFileList.map(x => {
                      return {
                        fileId: x.fileId,
                        fileKey: x.filePath,
                        fileName: x.fileName,
                        filePath: x.filePath,
                        mimeType: x.mimeType
                      }
                    })
                  }
                  this.sortIndex = 0
                  this.$refs.imgLightBox.init(postData)
                  this.visiblePreview = true
                }
              }
            }, '查看素材')
          }
        },
        {
          title: '未完成/已完成/总数',
          align: 'center',
          render: (h, { row }) => {
            return h('div', [
              h('span', { style: { color: row.statistic.notFinishCount > 0 ? '#ef4f4f' : '' } }, row.statistic.notFinishCount),
              h('span', ` / ${row.statistic.finishCount}`),
              h('span', ` / ${row.statistic.totalCount}`)
            ])
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(h('a', {
              style: { marginRight: '5px' },
              on: {
                click: () => {
                  this.onViewDetail(row)
                }
              }
            }, '详情'))
            if (row.statistic.notFinishCount > 0) {
              html.push(h('a', {
                on: {
                  click: () => {
                    this.onDone(row)
                  }
                }
              }, '履约'))
            }
            return h('div', html)
          }
        }
      ]
    }
  },
  computed: {
    uniqueCode () {
      return this.$store.state.workOrder.uniqueCode
    },
    startDate: {
      get () {
        return this.$store.state.workOrder.programQuerySdate
      },
      set (val) {
        this.$store.commit('set_program_query_sdate', val)
      }
    },
    endDate: {
      get () {
        return this.$store.state.workOrder.programQueryEdate
      },
      set (val) {
        this.$store.commit('set_program_query_edate', val)
      }
    }
  },
  created () {
    if (!this.uniqueCode) {
      const nowDate = new Date()
      const eDate = new Date().setMonth(nowDate.getMonth() + 1)
      this.dateRage = [ParseDate(nowDate), ParseDate(eDate)]
    } else {
      this.dateRage = [this.startDate, this.endDate]
    }
    this.query.startDate = this.dateRage[0]
    this.query.endDate = this.dateRage[1]
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.tableLoading = true
      this.startDate = this.query.startDate
      this.endDate = this.query.endDate
      getElecProgramPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.getTableData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onViewDetail (params) {
      this.$store.commit('set_unique_code', params.uniqueCode)
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setLeftComponent('')
      this.setBottomComponent('EleProgramDetail')
      this.setRightComponent('')

      // 设置头部路由标签
      this.setLinkTagArray({ key: 'eleProgramDetail', value: '节目单详情' })
      this.setLinkRouterMaps(['eleProgramDetail', () => {
        this.setShowLeftBlock(false)
        this.setShowRightBlock(false)
        this.setLeftComponent('')
        this.setBottomComponent('EleProgramDetail')
        this.setRightComponent('')
      }])
      this.setActivedTagName('eleProgramDetail')
    },
    onDone (params) {
      this.selectedUniqueCode = params.uniqueCode
      this.$nextTick(() => {
        this.$refs.done.init()
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    }
  }
}
</script>
